import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header3 from "../../assets/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container vertical">
          <h1 className="title">Centro Terapêutico de Recuperação</h1>

          <p className="text">
            Eficácia e qualidade no tratamento contra dependência química,
            alcoólica, depressão e vícios em jogos.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title text_center">
            Tenha de volta uma vida digna e saudável
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <figure className="left">
            <img src={header3} alt="Sobre nós" />
          </figure>

          <div className="right">
            <h2 className="title">Sobre Nós</h2>

            <figure className="mobile">
              <img src={header3} alt="Sobre nós" />
            </figure>

            <p className="text">
              Somos a Sobriedade Clinical, uma rede de clínicas especializadas
              em reabilitar pessoas com dependência química, alcóolica, vícios e
              distúrbios mentais. Contamos com várias unidades na Bahia, sendo
              algumas para atender o público feminino e outras para o masculino,
              todas com estrutura completa para acolher nossos pacientes. Além
              de profissionais dedicados e comprometidos em realizar um trabalho
              humanizado e de excelência na recuperação.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
