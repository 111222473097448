import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about2_6 from "../../assets/about2_6.png";
import about3_left1 from "../../assets/about3_left1.png";
import about3_left2 from "../../assets/about3_left2.png";
import about3_left3 from "../../assets/about3_left3.png";
import about3_left4 from "../../assets/about3_left4.png";
import about3_left5 from "../../assets/about3_left5.png";
import about3_left6 from "../../assets/about3_left6.png";
import about3_right1 from "../../assets/about3_right1.png";
import about3_right2 from "../../assets/about3_right2.png";
import about3_right3 from "../../assets/about3_right3.png";
import about3_right4 from "../../assets/about3_right4.png";
import about3_right5 from "../../assets/about3_right5.png";
import about3_right6 from "../../assets/about3_right6.png";
import about3_right7 from "../../assets/about3_right7.png";
import about3_right8 from "../../assets/about3_right8.png";
import about3_right9 from "../../assets/about3_right9.png";
import about3_right10 from "../../assets/about3_right10.png";
import about3_right11 from "../../assets/about3_right11.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about1_1,
    title: "Tratamento Especializado para o Alcoolismo",
    text: "Não deixe a dependência química controlar sua vida! Se você está lutando contra o vício químico, saiba que a ajuda está disponível. Entre em contato conosco agora.",
  },
  {
    id: 2,
    image: about1_2,
    title: "Tratamento Para Dependência Química",
    text: "Encontre a ajuda que você precisa para superar a dependência química e comece a sua jornada de recuperação hoje mesmo com o nosso tratamento.",
  },
  {
    id: 3,
    image: about1_3,
    title: "Tratamento Para a Depressão",
    text: "Não deixe a depressão controlar a sua vida. Busque ajuda especializada com o nosso tratamento especialziado contra a depressão e recupere o prazer de viver.",
  },
  {
    id: 4,
    image: about1_4,
    title: "Apoio veicular para remoção e tratamento involuntário",
    text: "Este serviço é essencial para garantir o transporte seguro dos pacientes que se negam a receber o tratamento de forma voluntária. Nossa equipe está disponível 24h por dia para atendê-los.",
  },
  {
    id: 5,
    image: about1_5,
    title: "Tratamento para Saúde Mental",
    text: "Buscando equilíbrio e bem-estar mental? Na Sobriedade Clinical, oferecemos um refúgio acolhedor e suporte especializado para fortalecer sua saúde mental. Restaure a harmonia e a paz em sua vida conosco!",
  },
];

const data2 = [
  {
    id: 1,
    image: about3_right1,
  },
  {
    id: 2,
    image: about3_right2,
  },
  {
    id: 3,
    image: about3_right3,
  },
  {
    id: 4,
    image: about3_right4,
  },
  {
    id: 5,
    image: about3_right5,
  },
  {
    id: 6,
    image: about3_right6,
  },
  {
    id: 7,
    image: about3_right7,
  },
  {
    id: 8,
    image: about3_right8,
  },
  {
    id: 9,
    image: about3_right9,
  },
  {
    id: 10,
    image: about3_right10,
  },
  {
    id: 11,
    image: about3_right11,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">NOSSOS TRATAMENTOS</h2>

          <p className="text">
            Além do tratamento de recuperação humanizada, também oferecemos
            <br />{" "}
            <span> o serviço de resgate de pacientes (remoção) in loco.</span>
          </p>

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            data.map(({ id, title, text, image }) => {
              return (
                <div key={id} className="item">
                  <figure className="top">
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>

                  <p className="item_title">{title}</p>

                  <p className="item_text">{text}</p>

                  <CtaWpp />
                </div>
              );
            })
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={4}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, title, text, image }) => {
                return (
                  <SwiperSlide key={id} className="item">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>

                    <p className="item_title">{title}</p>

                    <p className="item_text">{text}</p>

                    <CtaWpp />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">
            Profissionais Qualificados e Prontos para Atender
          </h2>

          <p className="text">
            Nossos profissionais estarão prontos para lhe receber e acolher de
            forma humanizada, com amor e espiritualidade (sem denominação
            religiosa). <b> Contamos com:</b>
          </p>

          <div className="gallery">
            <figure>
              <img src={about2_1} alt="️Psiquiatra" />
            </figure>

            <figure>
              <img src={about2_2} alt="Terapeuta Holístico" />
            </figure>

            <figure>
              <img src={about2_3} alt="Psicólogo" />
            </figure>

            <figure>
              <img src={about2_4} alt="Enfermaria" />
            </figure>

            <figure>
              <img src={about2_5} alt="Cozinheiro" />
            </figure>

            <figure>
              <img src={about2_6} alt="Médico Clínico Geral" />
            </figure>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="left">
            <h2 className="title">Nossa Estrutura</h2>

            <p className="text">
              Oferecemos uma estrutura completa, com lazer, dormitórios,
              alimentação regular e saudável. Tudo para acolher nossos pacientes
              de forma digna e confortável, promovendo assim uma recuperação
              mais ágil e eficaz:
            </p>

            <Swiper
              className="mobile"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data2.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="item">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="gallery">
              <figure>
                <img src={about3_left1} alt="Refeitório" />
              </figure>

              <figure>
                <img src={about3_left2} alt="Salão de TV" />
              </figure>

              <figure>
                <img src={about3_left3} alt="Enfermaria" />
              </figure>

              <figure>
                <img
                  src={about3_left4}
                  alt="Quartos com banheiro e Ar-condicionado"
                />
              </figure>

              <figure>
                <img src={about3_left5} alt="Piscina" />
              </figure>

              <figure>
                <img src={about3_left6} alt="Campo de Futebol" />
              </figure>
            </div>

            <p className="text2">
              <b>Oferecemos 4 Refeições diárias: </b> Café da manhã; Almoço;
              Lanche e Jantar.
            </p>

            <CtaWpp />
          </div>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data2.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="item">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </article>
    </section>
  );
};

export default About;
