import React, { useState, useEffect } from "react";
import "./styles.css";

import FormasPagamento from "../../components/FormasPagamento";
import CtaTelWpp from "../../components/CTATelWpp";
import CtaWpp from "../../components/CTAWpp";

import content1_1 from "../../assets/content1_1.png";
import content1_2 from "../../assets/content1_2.png";
import content1_1_1 from "../../assets/content1_1_1.png";
import content1_1_2 from "../../assets/content1_1_2.png";
import content1_1_3 from "../../assets/content1_1_3.png";
import content1_1_4 from "../../assets/content1_1_4.png";
import content1_1_5 from "../../assets/content1_1_5.png";
import content1_1_6 from "../../assets/content1_1_6.png";
import content1_1_7 from "../../assets/content1_1_7.png";
import content1_1_8 from "../../assets/content1_1_8.png";
import content1_1_9 from "../../assets/content1_1_9.png";
import content1_1_10 from "../../assets/content1_1_10.png";
import content1_1_11 from "../../assets/content1_1_11.png";
import content1_1_12 from "../../assets/content1_1_12.png";
import content1_2_1 from "../../assets/content1_2_1.png";
import content1_2_2 from "../../assets/content1_2_2.png";
import content1_2_3 from "../../assets/content1_2_3.png";
import content1_2_4 from "../../assets/content1_2_4.png";
import content1_2_5 from "../../assets/content1_2_5.png";
import content1_2_6 from "../../assets/content1_2_6.png";
import content1_2_7 from "../../assets/content1_2_7.png";
import content1_2_8 from "../../assets/content1_2_8.png";
import content1_2_9 from "../../assets/content1_2_9.png";
import content1_2_10 from "../../assets/content1_2_10.png";
import content1_2_11 from "../../assets/content1_2_11.png";
import content1_2_12 from "../../assets/content1_2_12.png";
import content1_3 from "../../assets/content1_3.png";
import content1_3_1 from "../../assets/content1_3_1.png";
import content1_3_2 from "../../assets/content1_3_2.png";
import content1_3_3 from "../../assets/content1_3_3.png";
import content1_3_4 from "../../assets/content1_3_4.png";
import content1_3_5 from "../../assets/content1_3_5.png";
import content1_3_6 from "../../assets/content1_3_6.png";
import content1_3_7 from "../../assets/content1_3_7.png";
import content1_3_8 from "../../assets/content1_3_8.png";
import content1_3_9 from "../../assets/content1_3_9.png";
import content1_3_10 from "../../assets/content1_3_10.png";
import content1_3_11 from "../../assets/content1_3_11.png";
import content1_3_12 from "../../assets/content1_3_12.png";

const chunkImages = (images, size) => {
  const chunks = [];
  for (let i = 0; i < images.length; i += size) {
    chunks.push(images.slice(i, i + size));
  }
  return chunks;
};

const galleryImages1 = [
  content1_1_1,
  content1_1_2,
  content1_1_3,
  content1_1_4,
  content1_1_5,
  content1_1_6,
  content1_1_7,
  content1_1_8,
  content1_1_9,
  content1_1_10,
  content1_1_11,
  content1_1_12,
];

const galleryImages2 = [
  content1_2_1,
  content1_2_2,
  content1_2_3,
  content1_2_4,
  content1_2_5,
  content1_2_6,
  content1_2_7,
  content1_2_8,
  content1_2_9,
  content1_2_10,
  content1_2_11,
  content1_2_12,
];

const galleryImages3 = [
  content1_3_1,
  content1_3_2,
  content1_3_3,
  content1_3_4,
  content1_3_5,
  content1_3_6,
  content1_3_7,
  content1_3_8,
  content1_3_9,
  content1_3_10,
  content1_3_11,
  content1_3_12,
];

const Modal = ({ isOpen, onClose, images, imagesPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const imageChunks = chunkImages(images, imagesPerPage);

  if (!isOpen) return null;

  const handlePrev = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? imageChunks.length - 1 : prevPage - 1
    );
  };

  const handleNext = () => {
    setCurrentPage((prevPage) =>
      prevPage === imageChunks.length - 1 ? 0 : prevPage + 1
    );
  };

  return (
    <div id="modal">
      <span className="close" onClick={onClose}>
        &times;
      </span>

      <div className="modal-content">
        <div className="image-grid">
          {imageChunks[currentPage].map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Gallery ${currentPage * imagesPerPage + index + 1}`}
            />
          ))}
        </div>

        <div className="navigation">
          <button onClick={handlePrev}>&lt;</button>
          <button onClick={handleNext}>&gt;</button>
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [imagesPerPage, setImagesPerPage] = useState(4);

  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = () => {
    setIsModalOpen3(true);
  };

  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  useEffect(() => {
    const updateImagesPerPage = () => {
      setImagesPerPage(window.innerWidth <= 768 ? 2 : 4);
    };

    updateImagesPerPage();
    window.addEventListener("resize", updateImagesPerPage);

    return () => {
      window.removeEventListener("resize", updateImagesPerPage);
    };
  }, []);

  return (
    <section id="content">
      <article className="content1">
        <div className="container vertical">
          <h2 className="title">Nossas unidades</h2>

          <p className="text">
            Nossas unidade possuem um espaço de vivência amplo, com área de
            lazer, piscina, dormitórios, refeitórios e muito mais!
          </p>

          <p className="text2">
            Agende uma visita à unidade mais próxima de você!
          </p>

          <div className="gallery">
            <div className="item">
              <figure onClick={openModal1}>
                <img src={content1_1_8} alt="Unidade Masculina" />
              </figure>

              <p className="item_title">Unidade Simões Filho - BA</p>

              <p className="item_text">Unidade Masculina</p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure onClick={openModal2}>
                <img src={content1_2} alt="Unidade Feminina" />
              </figure>

              <p className="item_title">Unidade Camaçari - BA </p>

              <p className="item_text">Unidade Feminina</p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure onClick={openModal3}>
                <img src={content1_3} alt="Unidade Masculina" />
              </figure>

              <p className="item_title">Unidade Camaçari - BA </p>

              <p className="item_text">Unidade Masculina</p>

              <CtaWpp />
            </div>
          </div>
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">
            NOSSAS UNIDADEs TEM QUANTIDADE REDUZIDA DE PACIENTES, E OFERECEMOS 5
            REFEIÇÕES DIÁRIAS!
          </h2>
        </div>
      </article>

      <FormasPagamento />

      <article className="content3">
        <div className="container vertical">
          <h2 className="title">
            Inscreva seu familiar em nosso programa de reabilitação!
          </h2>

          <p className="text">
            Além do tratamento de recuperação humanizada, também oferecemos o{" "}
            <br />
            <span>serviço de resgate de pacientes (remoção) in loco.</span>
          </p>

          <p className="text2">Fale conosco via telefone ou WhatsApp:</p>

          <CtaTelWpp />
        </div>
      </article>

      <Modal
        isOpen={isModalOpen1}
        onClose={closeModal1}
        images={galleryImages1}
        imagesPerPage={imagesPerPage}
      />
      <Modal
        isOpen={isModalOpen2}
        onClose={closeModal2}
        images={galleryImages2}
        imagesPerPage={imagesPerPage}
      />
      <Modal
        isOpen={isModalOpen3}
        onClose={closeModal3}
        images={galleryImages3}
        imagesPerPage={imagesPerPage}
      />
    </section>
  );
};

export default Content;
